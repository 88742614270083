<template>
    <div class="page-wrap">
        <div class="use-pic">
            <!-- <img src="../../assets/imgs/jump-pic.png" /> -->
        </div>
        <div class="wait">正在登陆中，请稍等 . . .</div>
    </div>
</template>

<script>
import { setCookies, removeCookies } from '@/utils/helper'
export default {
    name: "jump",
    data() {
        return {
            pageInfo: {},
            store_id: 0,
        }
    },
    created() {
        console.log('jump')
        // removeCookies('teeth-token')
        let token = this.$route.query.token
        if(token){
            setCookies('teeth-token', token)
            setTimeout(() => {
                this.$router.push({ path: '/admin' })
            }, 500);           
        }else{
            this.$Message.error('跳转失败')
            setTimeout(() => {
                this.$router.push({ path: '/' })
            }, 1500);
            return
        }
       
        
    }, 
    mounted() {
    },
    methods: {       
       
    }
}
</script>

<style scoped>
.page-wrap {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: #EDF0F9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.use-pic {
    width: 584px;
    height: 341px;
}

.use-pic img {
    width: 100%;
    height: 100%;
}

.wait {
    width: 351px;
    height: 30px;
    font-size: 30px;
    color: #545F78;
    margin-top: 20px;
}
</style>
